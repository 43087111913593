var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_authorCard",class:{
    'u-card2': _vm.context !== 'full',
  },attrs:{"data-context":_vm.context,"data-isself":_vm.is_self}},[_c(_vm.context !== 'full' ? 'router-link' : 'span',{tag:"component",staticClass:"_linkTo",attrs:{"to":_vm.author_url},nativeOn:{"click":function($event){_vm.context !== 'full' ? _vm.$emit('navToPage') : ''}}},[_c('div',{staticClass:"_topbar"},[_c('div',{staticClass:"_cover"},[_c('CoverField',{attrs:{"context":_vm.context,"cover":_vm.author.$cover,"title":_vm.$t('pick_portrait'),"preview_format":'circle',"path":_vm.author.$path,"placeholder":_vm.author.name.substring(0, 2),"can_edit":_vm.can_edit}})],1),(_vm.context === 'full')?_c('div',{staticClass:"u-spacingBottom"}):_vm._e(),_c('div',{staticClass:"_text"},[_c('div',{},[_c('TitleField',{attrs:{"field_name":'name',"content":_vm.author.name,"path":_vm.author.$path,"required":true,"minlength":3,"maxlength":40,"tag":_vm.context === 'full' ? 'h2' : 'h3',"can_edit":_vm.can_edit}}),(
              _vm.authorIsInstance({
                field: '$admins',
                folder_path: _vm.author.$path,
              })
            )?_c('div',{staticClass:"u-instructions"},[_c('small',[_c('b-icon',{attrs:{"icon":"shield-check","aria-label":_vm.$t('admin')}}),_vm._v(" "+_vm._s(_vm.$t("admin"))+" ")],1)]):_vm._e()],1),(_vm.context === 'full')?_c('div',{staticClass:"u-spacingBottom"}):_vm._e(),(_vm.author.group || _vm.can_edit)?_c('div',[_c('TagsField',{attrs:{"label":_vm.context === 'full' ? _vm.$t('group') : undefined,"field_name":'group',"tag_type":'accountgroup',"content":_vm.author.group,"path":_vm.author.$path,"can_edit":_vm.can_edit}})],1):_vm._e(),(_vm.context === 'full')?_c('div',{staticClass:"u-spacingBottom"}):_vm._e(),(_vm.can_edit)?_c('div',{staticClass:"u-spacingBottom"},[_c('TitleField',{attrs:{"field_name":'email',"label":_vm.context === 'full' ? _vm.$t('email') : undefined,"content":_vm.author.email,"path":_vm.author.$path,"required":_vm.$root.app_infos.instance_meta.require_mail_to_signup,"input_type":'email',"autocomplete":'email',"can_edit":_vm.can_edit}})],1):_vm._e(),(_vm.context === 'full' && (_vm.can_edit || !!_vm.author.presentation))?_c('div',{staticClass:"u-spacingBottom"},[_c('CollaborativeEditor2',{attrs:{"label":_vm.context === 'full' && (_vm.author.presentation || _vm.can_edit)
                ? _vm.$t('presentation')
                : undefined,"field_to_edit":'presentation',"path":_vm.author.$path,"content":_vm.author.presentation,"custom_formats":['bold', 'italic', 'link'],"is_collaborative":false,"can_edit":_vm.can_edit}})],1):_vm._e()]),(_vm.context === 'full' && (_vm.can_edit || !!_vm.author.$location))?_c('DetailsPane',{staticClass:"u-spacingBottom",attrs:{"header":_vm.$t('location'),"is_open_initially":true,"has_items":!!_vm.author.$location,"icon":'map'}},[_c('PositionPicker',{attrs:{"field_name":'$location',"content":_vm.author.$location,"path":_vm.author.$path,"can_edit":_vm.can_edit}})],1):_vm._e(),(_vm.can_edit)?_c('DetailsPane',{attrs:{"header":_vm.$t('options'),"icon":'gear'}},[(
            _vm.is_instance_admin &&
            _vm.context === 'full' &&
            !_vm.authorIsInstance({
              field: '$admins',
              folder_path: _vm.author.$path,
            })
          )?_c('div',{staticClass:"u-spacingBottom"},[_c('button',{staticClass:"u-buttonLink",attrs:{"type":"button"},on:{"click":function($event){_vm.show_settings_modal = true}}},[_vm._v(" "+_vm._s(_vm.$t("add_to_instance_admin"))+" ")]),(_vm.show_settings_modal)?_c('AdminSettings',{attrs:{"starting_tab":'administration_and_access_control'},on:{"close":function($event){_vm.show_settings_modal = false}}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"u-spacingBottom"},[_c('TitleField',{attrs:{"field_name":'$password',"label":_vm.$t('password'),"content":'',"path":_vm.author.$path,"required":true,"minlength":3,"maxlength":20,"input_type":'password',"can_edit":_vm.can_edit}})],1),_c('div',{},[_c('RemoveMenu',{attrs:{"remove_text":_vm.$t('remove_account'),"remove_expl":_vm.$t('remove_account_expl')},on:{"remove":_vm.removeAuthor}})],1)]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }