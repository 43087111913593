import { render, staticRenderFns } from "./DebugBtn.vue?vue&type=template&id=549b5173&scoped=true&"
import script from "./DebugBtn.vue?vue&type=script&lang=js&"
export * from "./DebugBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549b5173",
  null
  
)

export default component.exports