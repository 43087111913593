var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.author)?_c(_vm.component_tag,{tag:"component",staticClass:"_author",class:{
    'u-card2': _vm.component_tag === 'button' || _vm.component_tag === 'link',
  },attrs:{"type":_vm.component_tag === 'button' ? 'button' : '',"to":_vm.author_url,"title":_vm.author.name,"data-isself":_vm.is_self,"data-imageonly":_vm.show_image_only},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"_cover"},[_c('CoverField',{attrs:{"context":'preview',"cover":_vm.author.$cover,"title":_vm.$t('pick_portrait'),"path":_vm.author.$path,"placeholder":_vm.author.name.substring(0, 2),"preview_format":'circle',"can_edit":false}})],1),(!_vm.show_image_only)?_c('div',{staticClass:"_infos"},[_c('span',{staticClass:"_name"},[(
          _vm.authorIsInstance({
            field: '$admins',
            folder_path: _vm.author.$path,
          })
        )?_c('b-icon',{attrs:{"icon":"shield-check","aria-label":_vm.$t('admin')}}):_vm._e(),_vm._v(" "+_vm._s(_vm.author.name)+" ")],1)]):_vm._e(),(_vm.mode === 'select')?_c('b-icon',{attrs:{"icon":"box-arrow-in-right","aria-label":_vm.$t('select')}}):_vm._e(),(_vm.mode === 'add')?_c('b-icon',{attrs:{"icon":"plus-circle","aria-label":_vm.$t('add')}}):_vm._e(),(_vm.mode === 'remove')?_c('b-icon',{attrs:{"icon":"x-circle","aria-label":_vm.$t('remove')}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }