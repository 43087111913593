var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"u-button u-button_orange",attrs:{"type":"button"},on:{"click":function($event){_vm.show_modal = true}}},[_c('b-icon',{attrs:{"icon":'tools'}}),(['video', 'audio'].includes(_vm.media.$type))?[_vm._v(" "+_vm._s(_vm.$t("convert_shorten"))+" ")]:(_vm.media.$type === 'image')?[_vm._v(" "+_vm._s(_vm.$t("optimize"))+" ")]:_vm._e()],2),(_vm.show_modal)?_c('BaseModal2',{attrs:{"title":_vm.$t('convert_shorten'),"size":_vm.modal_width},on:{"close":function($event){_vm.show_modal = false}}},[_c('div',{staticClass:"_cont"},[(_vm.is_optimizing)?_c('LoaderSpinner',{staticClass:"_loader"}):_vm._e(),(!_vm.optimized_file)?_c('div',[(['video', 'audio'].includes(_vm.media.$type))?[_c('TrimMedia',{attrs:{"media":_vm.media,"extract_selection":_vm.extract_selection,"selection_start":_vm.selection_start,"selection_end":_vm.selection_end},on:{"update:extract_selection":function($event){_vm.extract_selection=$event},"update:selection_start":function($event){_vm.selection_start=$event},"update:selection_end":function($event){_vm.selection_end=$event}}}),_c('div',{staticClass:"u-spacingBottom"})]:_vm._e()],2):_c('div',[_c('div',{staticClass:"u-spacingBottom _mediaPreview",attrs:{"data-type":_vm.optimized_file.$type}},[_c('MediaContent',{attrs:{"file":_vm.optimized_file,"resolution":1600,"context":'full',"zoom_on_click":true,"show_fs_button":true}})],1),_c('div',{staticClass:"u-spacingBottom"},[_c('DLabel',{attrs:{"str":_vm.$t('size')}}),_c('div',{staticClass:"_comp"},[_c('span',[(_vm.media.$infos && _vm.media.$infos.size)?[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.media.$infos.size))+" ")]:[_vm._v(" ? ")]],2),_c('b-icon',{attrs:{"icon":"arrow-right-circle"}}),_c('strong',[(_vm.optimized_file.$infos && _vm.optimized_file.$infos.size)?[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.optimized_file.$infos.size))+" ")]:[_vm._v(" ? ")]],2)],1)],1),(
            _vm.optimized_file.$type === 'image' ||
            _vm.optimized_file.$type === 'video'
          )?_c('div',{staticClass:"u-spacingBottom"},[_c('DLabel',{attrs:{"str":_vm.$t('resolution')}}),_c('div',{staticClass:"_comp"},[_c('span',[(
                  _vm.media.$infos && _vm.media.$infos.width && _vm.media.$infos.height
                )?[_vm._v(" "+_vm._s(_vm.media.$infos.width + "×" + _vm.media.$infos.height)+" ")]:[_vm._v(" ? ")]],2),_c('b-icon',{attrs:{"icon":"arrow-right-circle"}}),_c('strong',[(
                  _vm.optimized_file.$infos &&
                  _vm.optimized_file.$infos.width &&
                  _vm.optimized_file.$infos.height
                )?[_vm._v(" "+_vm._s(_vm.optimized_file.$infos.width + "×" + _vm.optimized_file.$infos.height)+" ")]:[_vm._v(" ? ")]],2)],1)],1):_vm._e()])],1),_c('div',{staticClass:"_convertBtns",attrs:{"slot":"footer"},slot:"footer"},[(!_vm.optimized_file)?[_c('div',[_c('DLabel',{attrs:{"str":_vm.$t('quality')}}),(_vm.media.$optimized === true)?_c('div',{staticClass:"u-spacingBottom u-instructions"},[_vm._v(" "+_vm._s(_vm.$t("already_optimized"))+" ")]):_vm._e(),_c('div',{},[_c('SelectField2',{attrs:{"value":_vm.resolution_preset_picked,"options":_vm.presets,"can_edit":true,"hide_validation":true},on:{"change":function($event){_vm.resolution_preset_picked = $event}}})],1)],1),_c('div',[_c('div',[_c('button',{staticClass:"u-button u-button_bleuvert",attrs:{"type":"button"},on:{"click":_vm.optimizeMedia}},[_c('b-icon',{attrs:{"icon":"tools"}}),_vm._v(" "+_vm._s(_vm.$t("preview_new"))+" ")],1)]),_c('div',{staticClass:"u-instructions"},[_vm._v(" "+_vm._s(_vm.$t("wont_remove_original"))+" ")])])]:[_c('div',{staticClass:"_btnRow"},[_c('button',{staticClass:"u-button u-button_white",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_c('b-icon',{attrs:{"icon":"arrow-left-short"}}),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1),_c('button',{staticClass:"u-button u-button_bleuvert",attrs:{"type":"button"},on:{"click":_vm.keepBoth}},[_c('b-icon',{attrs:{"icon":"file-plus"}}),_vm._v(" "+_vm._s(_vm.$t("add_optimized_to_lib"))+" ")],1),_c('button',{staticClass:"u-button u-button_red",attrs:{"type":"button"},on:{"click":_vm.replaceOriginal}},[_c('b-icon',{attrs:{"icon":"save2-fill"}}),_vm._v(" "+_vm._s(_vm.$t("replace_original"))+" ")],1)])]],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }