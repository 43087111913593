var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal2',{staticClass:"_modal",attrs:{"title":_vm.$t('list_of_versions')},on:{"close":function($event){return _vm.$emit('close')}}},[(_vm.archives)?_c('div',{staticClass:"_archives"},[_c('div',{staticClass:"_topbar"},[_c('button',{staticClass:"u-button u-button_small",attrs:{"disabled":_vm.archive_shown_index === 0},on:{"click":_vm.newerVersion}},[_c('b-icon',{attrs:{"icon":"arrow-up"}}),_vm._v(" "+_vm._s(_vm.$t("recent"))+" ")],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_archive_filename),expression:"selected_archive_filename"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_archive_filename=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.archives),function(archive,index){return _c('option',{key:archive.filename,domProps:{"value":archive.filename,"textContent":_vm._s(
            archive.filename === 'current'
              ? _vm.$t('current')
              : _vm.formatDateTimeToPrecise(archive.date) +
                ' - version ' +
                (_vm.archives.length - index)
          )}})}),0),_c('button',{staticClass:"u-button u-button_small",attrs:{"disabled":_vm.archive_shown_index === _vm.archives.length - 1},on:{"click":_vm.olderVersion}},[_c('b-icon',{attrs:{"icon":"arrow-down"}}),_vm._v(" "+_vm._s(_vm.$t("ancient"))+" ")],1)]),_c('transition',{attrs:{"name":"pagechange","mode":"out-in","appear":""}},[(
          _vm.archive_shown &&
          (_vm.archive_shown.content || _vm.archive_shown.content === '')
        )?_c('div',{key:_vm.selected_archive_filename},[_c('div',{staticClass:"_archiveText"},[_c('CollaborativeEditor2',{attrs:{"content":_vm.archive_shown.content,"can_edit":false}})],1)]):_vm._e()])],1):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('SaveCancelButtons',{attrs:{"allow_save":_vm.selected_archive_filename !== 'current',"save_text":_vm.$t('restore_this_version')},on:{"save":function($event){return _vm.restoreVersion(_vm.archive_shown.content)},"cancel":function($event){return _vm.$emit('close')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }